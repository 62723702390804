<template>
  <div class="index">
    <div class="top">
      <div class="title-box">
        {{titleText}}
      </div>
      <div class="btn-box">
        <el-button
          type="primary"
          size="mini"
          @click="goback"
        >
          返回
        </el-button>
      </div>
    </div>
    <div class="box accumulate-box">
      <div v-for="(item, index) in accumulateList" :key="index" class="accumulate-box-item">
        <p>
          {{ item.name }}
        </p>
        <p>
          <span :style="index==0||index==1?'color:#02A7F0':'color: #00BFBF'">{{ item.sum }}</span>
          <span>{{ item.unit }}</span>
          <span :style="item.up?'color:#6EB501':'color:red'" class="icon-box">
            {{item.up?'⬆':'⬇'}}
          </span>
        </p>
        <div v-if="item.finish!==null" class="finish-box">
          <p class="finish-text-box"> 完成率<span>{{perConvert(item.finish)}}%</span></p>
          <p class="percentBorder">
            <span class="percentCenter" :style="'width:'+perConvertStyle(item.finish)+'%'"> </span>
          </p>
        </div>
      </div>
    </div>
    <div class="box analysis-box" id="analySisBox" v-loading="loadingAnalysis">
      <div class="analysis-top-box">
        <div class="title">
          销售数据分析(<span>{{chooseMonthtext}}</span>)
        </div>
        <div>
          <el-date-picker
            class="date-picker-month"
            v-model="analysisMonth"
            type="month"
            :editable="false"
            format="yyyy/MM"
            value-format="yyyyMM"
            size="mini"
            placeholder="请选择月份"
            :picker-options="startDatePicker"
            @change="changeMonth"
            :clearable="false"
          ></el-date-picker>
        </div>
      </div>
      <div>
        <table border="1" class="saleTarget-table">
          <thead>
            <tr>
              <th>统计类别</th>
              <th>销售额</th>
              <th style="min-width: 10vw;"></th>
            </tr>
          </thead>
          <tbody class="saleTarget-body">
            <tr v-for="(item,index) in analysisList" :key="index" >
              <td>{{item.name}}</td>
              <td>{{item.value}}</td>
              <td ></td>
            </tr>
            <tr v-for="(item,index) in analysisDayshowList" :key="index+'n'" >
              <td>{{Number(item.order_date)}}日</td>
              <td>{{numText(item.orders_sum)}}</td>
              <td>
                <p v-if="item.detailsShow" @click="openConstituteData(item)" class="viewData-box" >查看数据</p>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="load-btn" v-if="analysisDayList.length>7" @click="openOrRetract">
          {{loadMoreShow?'加载更多':'收起'}}
          <i :class="loadMoreShow?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
        </div>
      </div>
    </div>
    <div class="box data-constitute-box" id="dataConstituteBox" v-show="constituteShow" v-loading="loadingConstitute" >
      <div class="title">{{timeText}}数据构成分析</div>
      <div>
        <table border="1" class="saleTarget-table">
          <thead>
            <tr>
              <th>项目类别</th>
              <th>本日累计人份数</th>
              <th>本月累计人份数</th>
              <th>本年累计人份数</th>
            </tr>
          </thead>
          <tbody class="saleTarget-body">
            <tr v-for="(item,index) in constituteList" :key="index+'c'" :style="'background-color:'+item.color+';'">
              <td>{{item.name}}</td>
              <!-- <td>{{constituteReagent[item.value]?constituteReagent[item.value].day:''}}</td>
              <td>{{constituteReagent[item.value]?constituteReagent[item.value].month:''}}</td>
              <td>{{constituteReagent[item.value]?constituteReagent[item.value].year:''}}</td> -->
              <td>{{constituteReagent[item.value]?numText(constituteReagent[item.value].day):'-'}}</td>
              <td>{{constituteReagent[item.value]?numText(constituteReagent[item.value].month):'-'}}</td>
              <td>{{constituteReagent[item.value]?numText(constituteReagent[item.value].year):'-'}}</td>
            </tr>
            <tr class="sum-tr">
              <td>合计</td>
              <td>{{numText(constituteTotal.day)}}</td>
              <td>{{numText(constituteTotal.month)}}</td>
              <td>{{numText(constituteTotal.year)}}</td>
            </tr>
            <tr v-for="(item,index) in analysisDetails" :key="index" >
              <td>{{item.name}}</td>
              <td colspan='3' class="alignRight">{{numText(item.value)}}</td>
            </tr>
            <tr>
              <td >CRP/SAA试剂累计人份比</td>
              <td colspan='3' class="alignRight">{{CRPandSAA}}</td>
            </tr>
            <tr>
              <td>点评</td>
              <td colspan='3' style="text-align: center;">{{comment}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'

export default {
  data() {
    return {
      nowDate: '',
      query: '',
      titleText: '',
      startDatePicker: {
        disabledDate(time) {
          return new Date(2021,0,1).getTime() > time.getTime()
        }
      },
      accumulateList: [
        {
          name: '年度累计销售额',
          sum: '0.00',
          unit: '元',
          finish: 0,
          up: true,
        },
        {
          name: '年度累计人份数',
          sum: '0',
          unit: '人份',
          finish: null,
          up: true,
        },
        {
          name: '本月累计销售额',
          sum: '0.00',
          unit: '元',
          finish: 0,
          up: true,
        },
        {
          name: '本月累计人份数',
          sum: '0',
          unit: '人份',
          finish: null,
          up: true,
        },
      ],

      loadingAnalysis: false,
      analysisMonth: '',
      chooseMonth: '',
			analysisList: [
				{
					name: '累计销售额(万)',
					value: '',
				},
				{
					name: '总任务(万)',
					value: '',
				},
				{
					name: '完成率',
					value: '',
				},
				{
					name: '当月合计(万)',
					value: '',
				},
			],
      analysisDayList: [],
      analysisDayshowList: [],
			loadMoreShow: false,
      loadingConstitute: false,
      constituteShow: false,
      timeText: '',
      constituteList: [
				{
					name: 'TB-DOT',
					color: '#E3E391',
					value: 'TB-DOT',
				},
				{
					name: 'M-ST2',
					color: '#E6AFB6',
					value: 'M-ST2',
				},
				{
					name: 'M-proBNP',
					color: '#E6AFB6',
					value: 'M-proBNP',
				},
				{
					name: 'M-PCT',
					color: '#E6AFB6',
					value: 'M-PCT',
				},
				{
					name: 'M-心肌产品',
					color: '#E6AFB6',
					value: 'M-Cardiac',
				},
				{
					name: 'M-CS/SAA',
					color: '#E6AFB6',
					value: 'M-CS/SAA',
				},
				{
					name: 'C-发光产品',
					color: '#B1A0C7',
					value: 'C-light',
				},
				{
					name: 'S-SPOT',
					color: '#FFFF80',
					value: 'S-SPOT',
				},
				{
					name: 'O-SAA',
					color: '#FFFF80',
					value: 'O-SAA',
				},
				{
					name: 'C-SPOT',
					color: '#BCE8FB',
					value: 'C-SPOT',
				},
				{
					name: 'O-CRP',
					color: '#BCE8FB',
					value: 'O-CRP',
				},
				{
					name: 'O-MALB/ODD',
					color: '#BCE8FB',
					value: 'O-Malb/ODD/SB',
				},
				{
					name: 'O-HbA1c',
					color: '#C8C890',
					value: 'O-HbA1c',
				},
				{
					name: 'O-PCT',
					color: '#C8C890',
					value: 'O-PCT',
				},
				{
					name: 'A-SPOT',
					color: '#FBD39E',
					value: 'A-SPOT',
				},
				{
					name: 'D-SPOT',
					color: '#FBD39E',
					value: 'D-SPOT',
				},
				{
					name: 'H-SPOT',
					color: '#FBD39E',
					value: 'H-SPOT',
				},
				{
					name: '出口',
					color: '#CCFFFF',
					value: 'Exit',
				},
				{
					name: '质控品',
					color: '#CCFFFF',
					value: 'Qc',
				},
				{
					name: 'Qpad',
					color: '#77D0F7',
					value: 'Qpad',
				},
				{
					name: 'Maya-500',
					color: '#77D0F7',
					value: 'Maya-500',
				},
				{
					name: 'Ottoman',
					color: '#77D0F7',
					value: 'Ottoman',
				},
				{
					name: 'Ottoman-2020',
					color: '#77D0F7',
					value: 'Ottoman-2020',
				},
				{
					name: 'mini',
					color: '#77D0F7',
					value: 'mini',
				},
			],
			constituteReagent: {},
			constituteTotal: {},
			analysisDetails:[
			],
			CRPandSAA: '',
			comment: '',
    }
  },
  computed: {
    // 时间显示
		chooseMonthtext() {
			return `${this.chooseMonth.substring(0,4)}年${Number(this.chooseMonth.substring(4,6))}月`
		},
    // 数字格式化
    numText(){
			return (value)=> {
				if(value == ''||value == null){
					return '-'
				}
				return this.$tool.moneyFormatter(Number(value),0)
			};
		},
    perConvert() {
			return (per)=>{
				return (Number(per)*100).toFixed(2)
			}
		},
    perConvertStyle() {
			return (per)=>{
				return Number(per)>=1?100:(Number(per)*100).toFixed(2)
			}
		}
  },
 
  mounted() {
    this.query = JSON.parse(this.$route.query.query)
    this.nowDate = this.$route.query.nowDate
    this.titleText = this.query.user_name?this.query.user_name:this.query.province
   
    this.get_tbdaily_trade_entire();
    let nowDateTextList = this.nowDate.split('-')
		let analysisParam = {
			date: nowDateTextList[0]+nowDateTextList[1],
			// date: '202309',
			province_id: this.query.province_id
		}
    this.get_sales_analysis(analysisParam);
  },
  methods: {
    // 某人或某省销量情况
    get_tbdaily_trade_entire() {
			let params = {
				// start_day: this.nowDate.split('-').join(''),
				province_id: this.query.province_id
			}
			api.tbdaily_trade_entire(params).then(res => {
				if(res.code == 200){
					let year = res.data.year
          let month = res.data.month
          this.accumulateList[0].sum = year.orders.sum?this.$tool.moneyFormatter(year.orders.sum,2):0
          this.accumulateList[0].up = year.orders.up
          this.accumulateList[0].finish = year.orders.finish
          this.accumulateList[1].sum = year.amount.sum?this.$tool.moneyFormatter(year.amount.sum,0):0
          this.accumulateList[1].up = year.amount.up
          this.accumulateList[2].sum = month.orders.sum?this.$tool.moneyFormatter(month.orders.sum,2):0
          this.accumulateList[2].up = month.orders.up
          this.accumulateList[2].finish = month.orders.finish
          this.accumulateList[3].sum = month.amount.sum?this.$tool.moneyFormatter(month.amount.sum,0):0
          this.accumulateList[3].up = month.amount.up
				}
			})
		},
    // 切换月份
    changeMonth(value) {
      let nowDateTextList = this.nowDate.split('-')
      if(
					Number(nowDateTextList[0])<Number(value.substring(0,4))
					||(
						Number(nowDateTextList[0])==Number(value.substring(0,4))
					&&Number(nowDateTextList[1])<Number(value.substring(4,6))
						)
					){
        this.$message.warning('超过当前时间')
				return
			}
      let analysisParam = {
        date: value,
				province_id: this.query.province_id
      }
      this.get_sales_analysis(analysisParam)
    },
    // 获取销量分析
		get_sales_analysis(params) {
      // let params = {
      //   date: '202308',
			// 	province_id: this.query.province_id
      // }
      this.chooseMonth = params.date;
      let nowDateTextList = this.nowDate.split('-')
      this.loadingAnalysis = true;

			api.sales_analysis(params).then(res => {
				if(res.code == 200){
					let data = res.data
					this.analysisList[0].value = data.orders_sum===null?'-':data.orders_sum
					this.analysisList[1].value = data.target_cnt===null?'-':data.target_cnt
					this.analysisList[2].value = data.finish===null?'-':(Number(data.finish)*100).toFixed(2) +'%'
					this.analysisList[3].value = data.total_orders_sum===null?'-': data.total_orders_sum
			
					// 判断所选日期
					var listNum = 0;
					if(Number(nowDateTextList[0])==Number(params.date.substring(0,4))
					&&Number(nowDateTextList[1])==Number(params.date.substring(4,6))){
						// 当月
						listNum = Number(nowDateTextList[2])
					}else{ //小于当月
						listNum = Number(new Date(params.date.substring(0,4),params.date.substring(4,6),0).getDate());
					}
					let tempList = new Array(listNum).fill({})
					let sales_by_day = data.sales_by_day
					tempList = tempList.map((e,index)=> {
						let order_date = listNum-index 
						order_date = order_date<10?'0'+order_date:''+order_date
						let temp = sales_by_day.find(i=>i.order_date==order_date)
						if(temp){
							e = {
								...temp,
								detailsShow: true,
							}
						}else {
							e = {
								order_date,
								orders_sum: '',
								detailsShow: false,
							}
						}
						return e
					})
					// 设置数据
					this.analysisDayList = tempList
          this.loadingAnalysis = false;
          this.loadMoreShow = true;
					this.analysisDayshowList = this.analysisDayList.slice(0,7);
				}
			})
		},
    // 展开或者收起
		openOrRetract() {
			if(this.loadMoreShow){
				this.analysisDayshowList = this.analysisDayList
			}else{
				this.analysisDayshowList = this.analysisDayList.slice(0,7);
        this.$nextTick(()=> {
          document.querySelector('#analySisBox').scrollIntoView({
          });
        })
			}
			this.loadMoreShow = !this.loadMoreShow;
		},
    // 查看详情点击
    openConstituteData(item) {
      let params = {
        today: this.chooseMonth + item.order_date,
				province_id: this.query.province_id,
      }
      this.constituteShow = true;
      this.$nextTick(()=> {
        document.querySelector('#dataConstituteBox').scrollIntoView({
          behavior: "smooth"
        });
      })
      this.get_sales_constitute_analysis(params)
    },
    // 获取某日销售详情
    get_sales_constitute_analysis(params) {
      
      this.loadingConstitute = true;
			api.sales_constitute_analysis(params).then(res => {
				this.timeText = `${params.today.substring(0,4)}年${Number(params.today.substring(4,6))}月${Number(params.today.substring(6,8))}日`
				let data =res.data
				this.analysisDetails = [
					{
						name: '本日订单额',
						value: data.day_order,
					},
					{
						name: '本月累计国内',
						value: data.month_order_inland,
					},{
						name: '本月累计出口',
						value: data.month_order_exit,
					},{
						name: '本月累计\n国内+出口',
						value: data.month_order_inland_exit,
					},
					{
						name: 'Maya平台\n累计订单额',
						value: data.Maya_order_sum_total,
					},{
						name: 'Ottoman平台\n累计订单额',
						value: data.ottoman_order_sum_total,
					},{
						name: 'Qpad&TB平台\n累计订单额',
						value: data['Qpad&TB_order_sum_total'],
					}
				]
				this.CRPandSAA = data['CRP/SAA']===null?'-':data['CRP/SAA'].toFixed(2)
				this.comment = data.comment
				this.constituteReagent = data.reagent
				this.constituteTotal = data.total;
				this.loadingConstitute = false;
			})
		},
    // 返回上一页
    goback() {
      // this.$router.push({ path: '/dataBoard',})
      window.history.back()
    },
  },
}
</script>

<style lang="scss" scoped>
$gap: px(10);
$bordercolor: #efefef; //边框或线条颜色
.top {
  display: flex;
  justify-content: space-between;
  .title-box {
    font-weight: bold;
    color: #111111;
    margin-left: $gap;
    margin-right: $gap;
    font-size: $title-size;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .btn-box {
    margin-right: 2%;
  }
}
.box {
  background-color: #fff;
  padding: $gap;
  margin: $gap;
  border-radius: $gap/2;
  border: px(1) #eee solid;
}
.accumulate-box {
  display: flex;
  justify-content: space-between;
  .accumulate-box-item {
    flex: 1;
    text-align: center;
    .icon-box {
      font-weight: bold;
    }
    >p:nth-child(1){
      font-size: px(18);
      color: #444;
      font-weight: bold;
      font-family: "黑体", "微软雅黑", sans-serif;
    }
    >p:nth-child(2){
      font-size: px(30);
      margin: px(20) px(20) px(5);
      color: #444;
      span:nth-child(1){
        font-weight: bold;
        font-family: "黑体", "微软雅黑", sans-serif;
      }
      span:nth-child(2){
        margin: 0 px(5);
        font-size: px(16);
        color: #444;
        font-weight: bold;
        font-family: "黑体", "微软雅黑", sans-serif;
      }
      span:nth-child(3){
        font-weight: bold;
      }
    }
    .finish-box {
      .finish-text-box {
        text-align: left;
        margin-left: px(20);
        font-size: px(14);
        color: #999999;
        >span {
          margin-left: px(3);
          color:#70B603;
        }
      }
      .percentBorder {
        margin: px(10) px(20) px(20);
        height: 5px;
        border: px(1) solid #70B603;
        border-radius: 10px;
        width: calc(100% - px(40)) ;
        .percentCenter {
          display: block;
          background-color: #70B603;
          border-radius: 10px;
          height: 5px;
        }
      }
    }
  }
}
.analysis-box {
  .analysis-top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gap;
    .title {
      font-size: px(20);
      font-weight: bold;
      color: #858585;
      span {
        color: #008080;
      }
    }
  }
  tr>th {
    width: 33%;
  }
  .viewData-box {
    color: #02A7F0;
    cursor: pointer;
  }
  .load-btn {
    color: #999999;
    cursor: pointer;
    text-align: center;
    font-size: px(18);
    margin-top: px(10);
  }
}
.data-constitute-box {
  .title {
    font-size: px(20);
    font-weight: bold;
    color: #858585;
    margin-bottom: $gap;
  }
  tr {
    td {
      text-align: center;
    }
    .alignRight {
      text-align: right;
      padding-right: px(10);
   }
  }
  .sum-tr {
    color: #F59A23;
  }
    
}
.saleTarget-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-color: $bordercolor;
  tr { 
    width: 100%;
    text-align: center;
  }
  th {
    font-size: px(20);
  }
  
  td {
    font-size: px(18);
    padding: px(3);

  }	
  
}

</style>